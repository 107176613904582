@import '../../../antd-config';
@import '../../../assets/less/mixins';

.syllabus {
  margin-top: 30px;
  &__container {
    border: none !important;
    background-color: @white;
  }
  &__item {
    border: none !important;
    background-color: @gray15;
    
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .ant-collapse-header {
      color: @dark-blue;
      font-size: 1.286em;
      font-weight: 500;
      padding: 14px 25px !important;
      padding-right: 40px !important;
    }
    .ant-collapse-content {
      background-color: transparent;
      border-top: 1px solid @white;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .resource-item {
    align-items: center;
    color: @gray17;
    cursor: pointer;
    display: flex;
    margin-bottom: 10px;

    &.disabled {
      opacity: 0.6;
    }

    i {
      font-size: 1.8em;
    }

    span {
      display: inline-block;
      padding-left: 10px;
    }
  }

  .syllabus-unavailable {
    text-align: center;
  }
}

.language-selector {
  &__container {
    display: flex;
    justify-content: flex-end;

    .custom-select {
      width: auto;
    }

    .ant-select-selection {
      background-color: @gray7;
    }
  }
}

.preview-tag{
  margin-left: auto;
  background-color: #31bdbf;
  color: white;
  text-align: center;
  font-weight: 600;
  padding: 0.3rem 1.4rem !important;
  border-radius: 8px;
}
@primary-color: #31bdbf;@dark-blue: #252c43;@dark-blue2: #334557;@dark-blue3: #2d3f4f;@dark-blue4: #212529;@dark-blue5: #334657;@gray1: #f0f0f1;@gray2: #e9e9ec;@gray3: #f1f1f2;@gray4: #d2d2d2;@gray5: #5d5d60;@gray6: #e8e8e8;@gray7: #f0f0f2;@gray8: #6a747d;@gray9: #69767f;@gray10: #848e97;@gray11: #334559;@gray12: #e9e8ed;@gray13: #54545c;@gray14: #707c89;@gray15: #f7f7f8;@gray16: #899299;@gray17: #52555d;@gray18: #e7e7ea;@gray19: #8f99a3;@gray20: #f7f7f9;@gray21: #7e8790;@gray22: #e2e1e6;@gray23: #717179;@gray24: #a9a9ab;@gray25: #efeff1;@red: #f06269;@font-family: 'Poppins', sans-serif;